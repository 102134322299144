<script setup lang="ts">
import CartTrustees from '~/components/atoms/CartTrustees.vue'

const localePath = useLocalePath()
const drawers = useDrawers()
const priceFormatter = usePrice()
const { t, locales, locale } = useI18n()
const router = useRouter()

const { activeOrder } = useActiveOrder()

const reversedLines = computed(() => {
  return activeOrder.value?.lines.slice().reverse() || []
})

async function handleClose() {
  drawers.value.cart.open = false
  await nextTick()
}

async function redirectToCheckout() {
  await handleClose()
  await router.push(localePath('checkout'))
}

const showCart = computed(() => drawers.value.cart.open)
</script>

<template>
  <NDrawer id="cart-drawer" slide-from="right" :show="showCart" :title="t('cart.label')" closable @close="handleClose">
    <div class="mt-4 md:mt-8">
      <template v-if="activeOrder?.totalQuantity">
        <div class="flow-root">
          <ul role="list" class="-my-6 divide-y divide-slate-200">
            <CartLineItemNew
              v-for="line in reversedLines"
              :key="line.id"
              :line="line"
              :currency="activeOrder?.currency"
              class="py3"
              @click:link="handleClose"
            />
          </ul>
        </div>
      </template>

      <div v-else class="h-48 flex items-center justify-center text-xl text-slate-400">
        {{ t('cart.empty.heading') }}
      </div>
    </div>

    <template v-if="activeOrder?.totalQuantity" #footer>
      <ContentGuard>
        <div class="flex items-center justify-center">
          <div class="mb2 block w-fit rounded-md bg-primary-8 px2 py-0.5 text-xs">
            <NIcon icon="ph:clock" />&nbsp;<span> {{ t('trustable.products_in_your_cart_are_not_reserved') }}</span>
          </div>
        </div>
        <div class="z-200 border-1 border-t-gray-200 border-solid px1.5 sm:px-2 sm:pb-2">
          <div class="w-full">
            <div class="mx-auto block w-fit">
              <Blinker :shadow="false" :offset="2" />
            </div>
          </div>
        </div>
        <div class="trustees bg-green-200 p1 sm:p2">
          <CartTrustees />
        </div>
      </ContentGuard>

      <div class="bg-gray-100 px4 py3">
        <ContentGuard>
          <template v-if="activeOrder.subTotal">
            <div
              v-for="(item, i) in [
                { text: t('trustable.free_shipping'), icon: 'ph:check-bold', color: 'text-green-500' },
                { text: t('trustable.free_gift'), icon: 'ph:check-bold', color: 'text-green-500' },
                { text: t('trustable.convent_return_goods_within_100_days_short'), icon: 'ph:check-bold', color: 'text-green-500' },
              ]"
              :key="i"
              class="flex justify-between text-xs text-slate-900 sm:text-sm"
            >
              <p>{{ item.text }}</p>
              <NIcon :icon="item.icon" :class="item.color" />
            </div>
          </template>
        </ContentGuard>

        <div class="flex justify-between pt-1 text-base text-slate-900 font-bold">
          <p>{{ t('order.summary.subtotal.label') }}</p>

          <p v-if="activeOrder.subTotal">
            <span class="font-bold">
              {{ priceFormatter(activeOrder.subTotal, activeOrder.currency) }}
            </span>
          </p>
        </div>
      </div>
      <div class="px4 pb3">
        <div class="mt-2 md:mt-3">
          <div class="flex flex-col flex-gap2 md:flex-row">
            <NButton
              id="continue-shopping"
              class="w-full justify-center rounded-md text-black font-medium leading-8 md:leading-10"
              @click="handleClose"
            >
              {{ t('checkout.continue_shopping') }}
            </NButton>
            <NButton
              id="checkout"
              n="primary-2 solid"
              class="w-full justify-center rounded-md font-bold leading-8 md:leading-10"
              @click="redirectToCheckout"
            >
              {{ t('checkout.label') }}
            </NButton>
          </div>
        </div>
      </div>
    </template>
  </NDrawer>
</template>
